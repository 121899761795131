import "lazysizes";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
SwiperCore.use([Navigation, Pagination]);

window.addEventListener("DOMContentLoaded", () => {
  document.body.classList.add("show");

  setInterval(() => {
    showDots();
  }, 3000);

  // init slider
  const sliderEl = document.querySelector(".slider");
  const slider = new SwiperCore(sliderEl, {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 20,
    speed: 400,
    breakpoints: {
      768: {
        spaceBetween: 100,
      },
    },
    navigation: {
      nextEl: ".slider__btn--next",
      prevEl: ".slider__btn--prev",
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
    },
  });
});

// init langSwitch
const langSwitch = document.querySelector(".header__language");
const openState = "header__language--is-open";

function openLang() {
  langSwitch.classList.add(openState);
  document.documentElement.addEventListener("click", closeMenuOnBodyClick);
}

function closeMenu() {
  langSwitch.classList.remove(openState);
  document.documentElement.removeEventListener("click", closeMenuOnBodyClick);
}

function closeMenuOnBodyClick(event) {
  const path = event.composedPath();
  if (
    path.some(
      (elem) => elem.className === "header__language header__language--is-open"
    )
  ) {
    return;
  }
  closeMenu();
}

langSwitch.addEventListener("click", openLang);

//init wypoint nav

const navItems = document.querySelectorAll(".header__nav-item");

function scrollToId(el) {
  const target = el.getAttribute("data-scroll-id");
  const scrollTo = document.getElementById(target).offsetTop;
  scroll({
    top: scrollTo - 110,
    behavior: "smooth",
  });
}

navItems.forEach((navItem) => {
  navItem.addEventListener("click", function () {
    scrollToId(navItem);
  });
});

function generateRandomIntegerInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

let grid = [];

function showDots() {
  grid = [];
  for (let index = 0; index < 10; index++) {
    let value = generateRandomIntegerInRange(1000, 12000);
    grid.push(value);
  }

  var circles = document.querySelectorAll("circle");
  circles.forEach((circle, i) => {
    circle.classList.remove("pulse");
  });

  grid.forEach((gdot, i) => {
    document.querySelector(`.dot-${gdot}`).classList.add("pulse");
  });
}
showDots();
